import React from "react";
import { Link } from "react-router-dom";

import ailg from "../components/img/star.svg";
import ContactIgn from "../components/ContactIgn";

const Privacy = () => {
  return (
    <>
      <div className="ptii3  ">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-md-6">
              <h1 className="font2 font3 qaw text-dark text-start mb-5">
                Privacy <span className="aitxt">Policy </span>
              </h1>
            </div>

            <div className="col-md-6">
              <div className="abt2 font5 font-600 text-dark">
                This privacy policy sets out how <strong>IGN SOFTWARES PVT LTD</strong> uses and protects
                any information that you provide to <strong>IGN SOFTWARES PVT LTD</strong> when you use
                this website.
              </div>
            </div>

            <div className="col-12">
              <p>
                <strong>IGN SOFTWARES PVT LTD</strong> is committed to ensuring that your privacy is
                protected. Should we ask you to provide certain information by
                which you can be identified when using this website, then you
                can be assured that it will only be used in accordance with this
                privacy statement.
              </p>

              <p>
                <strong>IGN SOFTWARES PVT LTD</strong> may change this policy from time to time by
                updating this page. You should check this page from time to time
                to ensure that you are happy with any changes. This policy is
                revised and effective from 01/01/2016.
              </p>
              <p>What we collect: We may collect the following information</p>
              <li> Name and Job title</li>
              <li>
                Contact Information including email address & phone number
              </li>
              <li>
                Demographic Information such as City, postcode, preferences
                and interests
              </li>
              <li>
                Other Information relevant to service enquiry, customer
                surveys and/or offers
              </li>

              <p>What we do with the information we gather</p>
              <p>
                We require this information to understand your needs and provide
                you with a best service, and in particular for the following
                reasons:
              </p>

              <li>Internal record keeping.</li>
              <li>
                 We may use the information to improve our products and
                services.
              </li>

              <li>
                 We may periodically send promotional emails about new
                products, special offers or other information which we think you
                may find interesting using the email address which you have
                provided.
              </li>
              <li>
                 From time to time, we may also use your information to contact
                you for feedback, market research purposes. We may contact you
                by email, phone, fax or mail. We may use the information to
                customise the website according to your interests.
              </li>
              <p>Security</p>
              <p>
                We are committed to ensuring that your information is secure. In
                order to prevent unauthorised access or disclosure we have put
                in place suitable physical, electronic and managerial procedures
                to safeguard and secure the information we collect online.
              </p>
              <p>How we use cookies</p>
              <p>
                A cookie is a small file which asks permission to be placed on
                your computer's hard drive. Once you agree, the file is added
                and the cookie helps analyse web traffic or lets you know when
                you visit a particular site. Cookies allow web applications to
                respond to you as an individual. The web application can tailor
                its operations to your needs, likes and dislikes by gathering
                and remembering information about your preferences.
              </p>
              <p>
                We use traffic log cookies to identify which pages are being
                used. This helps us analyse data about webpage traffic and
                improve our website in order to tailor it to customer needs. We
                only use this information for statistical analysis purposes and
                then the data is removed from the system. Overall, cookies help
                us provide you with a better website, by enabling us to monitor
                which pages you find useful and which you do not. A cookie in no
                way gives us access to your computer or any information about
                you, other than the data you choose to share with us. You can
                choose to accept or decline cookies. Most web browsers
                automatically accept cookies, but you can usually modify your
                browser setting to decline cookies if you prefer. This may
                prevent you from taking full advantage of the website.
              </p>

              <p>Links to other websites</p>
              <p>
                Our website may contain links to other websites of interest.
                However, once you have used these links to leave our site, you
                should note that we do not have any control over that other
                website. Therefore, we cannot be responsible for the protection
                and privacy of any information which you provide whilst visiting
                such sites and such sites are not governed by this privacy
                statement. You should exercise caution and look at the privacy
                statement applicable to the website in question.
              </p>
              <p>Controlling your Personal Information</p>
              <p>
                You may choose to restrict the collection or use of your
                personal information in the following ways:
              </p>
              <li>
                whenever you are asked to fill in a form on the website, look
                for the box that you can click to indicate that you do not want
                the information to be used by anybody for promotional purposes.
                If such box is not available, you may choose not to fill such
                form. However, by submitting the filled enquiry form, you will
                be construed to have foregone your right and Company may choose
                to send promotional emails and materials from time to time.
              </li>
              <li>
                if you have previously agreed to us using your personal
                information for promotional purposes, you may change your mind
                at any time by writing to or emailing us at
                support@@ignsoftwares.in
              </li>
              <p>
                We will not sell, distribute or lease your personal information
                to third parties unless we have your permission or are required
                by law to do so. We may use your personal information to send
                you promotional information about third parties which we think
                you may find interesting.
              </p>
              <p>Contacting Us</p>
              <p>
                If there are any questions regarding this privacy policy you may
                contact us using the information below:
              </p>
              <p>IGN SOFTWARES PVT LTD</p>
              <p>support@ignsoftwares.in</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;

import React from "react";
import { Link } from "react-router-dom";

import ailg from "../components/img/star.svg";
import ContactIgn from "../components/ContactIgn";

const Cancellation = () => {
  return (
    <>
      <div className="ptii3  ">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-md-6">
              <h1 className="font2 font3 qaw text-dark text-start mb-5">
                Cancellation & Refund <span className="aitxt">Policy </span>
              </h1>
            </div>

            <div className="col-md-6">
              <div className="abt2 font5 font-600 text-dark">
                <strong>IGN SOFTWARES PVT LTD</strong> believes in helping its
                customers as far as possible, and has therefore a liberal
                cancellation policy. Under this policy:
              </div>
            </div>

            <div className="col-12">
              <li>
                Cancellations will be considered only if the request is made
                within 24 hours of placing an order. However, the cancellation
                request will not be entertained if the orders have been
                communicated to the operational team and Operations team has
                initiated the process of assignment. Cancellation and refund of
                fees or charges will be strictly governed by the Company Refund
                Policy of the company informed and declared from time to time.
              </li>
              <li>
                There is no cancellation of orders placed under the Same day
                delivery category.
              </li>
              <li>
                No cancellations are entertained for those products/services
                that the <strong>IGN SOFTWARES PVT LTD</strong> marketing team
                has obtained on special discounts and offers on occasions like
                New Year, Pongal, Diwali, Independence Day, Foundation Day etc.
                These are limited occasion offers and therefore cancellations
                are not possible.
              </li>

              <p className="mt-4">
                When you buy our products/services, your purchase is covered by
                our 24 hours money back guarantee. If you are, for any reason,
                not entirely happy with your purchase, we will cheerfully issue
                a full refund, subject to deductions for legal documents or
                services provided. To request a refund under this guarantee, you
                must contact us within the first 24 hours of your payment. Just
                send an email to support@ignsoftwares.in . We'll gladly refund
                you 100% of your fees within 24-72 hours of your refund request.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cancellation;

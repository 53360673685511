import React from "react";
import { Link } from "react-router-dom";

import ailg from "../components/img/star.svg";
import ContactIgn from "../components/ContactIgn";

const Terms = () => {
  return (
    <>
      <div className="ptii3  ">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-md-6">
              <h1 className="font2 font3 qaw text-dark text-start mb-5">
                Terms & <span className="aitxt">Conditions </span>
              </h1>
            </div>

            <div className="col-md-6">
              <div className="abt2 font5 font-600 text-dark">
                Welcome to IGNSoftwares.in. ignsoftwares.in is owned and managed
                by IGN SOFTWARES Pvt. Ltd. If you continue to browse and use
                this website you are agreeing to comply with and be bound by the
                following terms and conditions of use, which together with our
                privacy policy govern ignsoftwares.in relationship with you in
                relation to this website.
              </div>
            </div>

            <div className="col-12">
              <h1></h1>

              <p>
                The term '<strong>IGN SOFTWARES PVT LTD</strong>' or 'us' or 'we' refers to the
                owner of the website whose registered office isC/o Sau Kumud
                Sudhakar Ruchake At Radkey Layout, Post Midctah, Hingna, Nagpur,
                Nagpur, Maharashtra, India, 440016 Our company registration
                number is <strong>U72200MH2022PTC394044</strong>, India. The term 'you' refers to
                the user or viewer of our website.
              </p>
              <p>
                The use of this website is subject to the following terms of
                use:
              </p>
              <li>
                The content of the pages of this website is for your general
                information and use only. It is subject to change without
                notice.
              </li>
              <li>
                Neither we nor any third parties provide any warranty or
                guarantee as to the accuracy, timeliness, performance,
                completeness or suitability of the information and materials
                found or offered on this website for any particular purpose. You
                acknowledge that such information and materials may contain
                inaccuracies or errors and we expressly exclude liability for
                any such inaccuracies or errors to the fullest extent permitted
                by law.
              </li>
              <li>
                • Your use of any information or materials on this website is
                entirely at your own risk, for which we shall not be liable. It
                shall be your own responsibility to ensure that any products,
                services or information available through this website meet your
                specific requirements.
              </li>
              <li>
                 This website contains material which is owned by or licensed
                to us. This material includes, but is not limited to, the
                design, layout, look, appearance and graphics. Reproduction is
                prohibited other than in accordance with the copyright notice,
                which forms part of these terms and conditions. All trademarks
                reproduced in this website which are not the property of, or
                licensed to <strong>IGN SOFTWARES PVT LTD</strong> are acknowledged on the website.
              </li>
              <li>
                 Unauthorised use of this website may give rise to a claim for
                damages and/or be a criminal offence. From time to time this
                website may also include links to other websites. These links
                are provided for your convenience to provide further
                information. They do not signify that we endorse the website(s).
                We have no responsibility for the content of the linked
                website(s).
              </li>
              <li>
                  You may not create a link to this website from another website
                or document without <strong>IGN SOFTWARES PVT LTD</strong>'s prior written consent. Your
                use of this website and any dispute arising out of such use of
                the website is subject to the laws of India or regulatory
                authority within the country of India.
              </li>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
